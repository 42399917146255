import { type FC } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import styles from './top.module.scss';
// import { swapCoinModel } from '@models/swap-coin.model';
import { useNavigate } from 'react-router-dom';

export const Top: FC = () => {
  const navigator = useNavigate();

  const banners = [
    // {
    //   className: styles.hiddo,
    //   clickEvent: () => {
    //     swapCoinModel.updateB(
    //       '0x5b40e84bd4b428fb7ae15a4a36ed1527d1b7bfaeae21855a499714175d215c2e::sai::SAI',
    //     );
    //     swapCoinModel.updateA('0x2::sui::SUI');
    //     navigator(
    //       '/trade?input=0x2::sui::SUI&output=0x5b40e84bd4b428fb7ae15a4a36ed1527d1b7bfaeae21855a499714175d215c2e::sai::SAI',
    //     );
    //   },
    // },
    {
      className: styles.lbtc,
      clickEvent: () => {
        // swapCoinModel.updateB(
        //   '0xe9a376ada59b2ad927eaedc086dd0d7649b56433598ceda4f9d86ab95309e651::puff::PUFF',
        // );
        // swapCoinModel.updateA('0x2::sui::SUI');
        navigator(
          '/pools/0x13490343cab8e8669dccb4cb1a08f7ea090e553c629868b1dad1760027299b12/add-liquidity',
        );
      },
    },
    // {
    //   className: styles.grass,
    //   clickEvent: () => {
    //     swapCoinModel.updateB(
    //       '0x6ad7368bf6bbe077cfd30adbc07037767596bc922934d2908027f2ab092c7530::grass::GRASS',
    //     );
    //     swapCoinModel.updateA('0x2::sui::SUI');
    //     navigator(
    //       '/trade?input=0x2::sui::SUI&output=0x6ad7368bf6bbe077cfd30adbc07037767596bc922934d2908027f2ab092c7530::grass::GRASS',
    //     );
    //   },
    // },
    // {
    //   className: styles.sumi,
    //   clickEvent: () => {
    //     swapCoinModel.updateB(
    //       '0x1f36ef86487579d4997a86861369be9815eef3c1b547bf61b92de18ed2359208::suimba::SUIMBA',
    //     );
    //     swapCoinModel.updateA('0x2::sui::SUI');
    //     navigator(
    //       '/trade?input=0x2::sui::SUI&output=0x1f36ef86487579d4997a86861369be9815eef3c1b547bf61b92de18ed2359208::suimba::SUIMBA',
    //     );
    //   },
    // },
    // {
    //   className: styles.uicide,
    //   clickEvent: () => {
    //     swapCoinModel.updateB(
    //       '0x1b16598b6c00ff66939d14d72a06b49b2a80578a76e3cd813a7be0a69818f03f::uicide::UICIDE',
    //     );
    //     swapCoinModel.updateA('0x2::sui::SUI');
    //     navigator(
    //       '/trade?input=0x2::sui::SUI&output=0x1b16598b6c00ff66939d14d72a06b49b2a80578a76e3cd813a7be0a69818f03f::uicide::UICIDE',
    //     );
    //   },
    // },
  ];

  const finalBalances = banners.sort(() => Math.random() - 0.5);
  return (
    <div className={styles.top}>
      <Swiper
        modules={[Autoplay, Pagination]}
        centeredSlides={true}
        loop={true}
        autoplay={{
          delay: 6000,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
      >
        {/* <SwiperSlide>
          <div
            className={`${styles.slide} ${styles.ns}`}
            onClick={() => {
              navigator(
                '/pools/0xa0b55c090a286dfc77125231735077bc12a7bf865feb07accdac6f5be03f69b4/add-liquidity',
              );
            }}
          ></div>
        </SwiperSlide> */}
        {finalBalances.map((item, index) => (
          <SwiperSlide key={index}>
            <div className={`${styles.slide} ${item.className}`} onClick={item.clickEvent}></div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};
